




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';

import SelectionFilter from '@/shared/components/filters/SelectionFilter.vue';
import { DateType } from '../models/date-filter.model';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';
import DateFilter from '@/shared/components/filters/DateFilter.vue';
import YMSTypeFilter from '@/pages/YMS/components/YMSTypeFilter.vue';
import moment from 'moment-timezone';
import { BS_DATEPICKER_FORMAT } from '@/utils/date.util';

import { YmsModule } from '@/store/index';
import { YmsType } from '@/pages/YMS/models/yms.model';

@Component({
  components: { SelectionFilter, DIVFilter, YMSTypeFilter, DateFilter }
})
export default class YMSFilters extends Vue {
  @Prop() type: YmsType;

  @Watch('$route', { immediate: true })
  onRouterChange(val, oldVal) {
    YmsModule.search({
      type: this.type
    });
  }

  typeList = [
    { value: null, text: 'All' },
    { value: 'IN - PULLOUT', text: 'IN - PULLOUT' },
    { value: 'IN - LINE', text: 'IN - LINE' },
    { value: 'IN - PICKUP', text: 'IN - PICKUP' },
    { value: 'OUT - DROP/DELIVERY', text: 'OUT - DROP/DELIVERY' },
    { value: 'OUT - LINE', text: 'OUT - LINE' },
    { value: 'OUT - RETURN', text: 'OUT - RETURN' },
    { value: 'BARE', text: 'BARE' },
    { value: 'FLIP', text: 'FLIP' }
  ];

  selectionTypes = {
    list: [
      { value: 'containerNumber', text: 'CONT #' },
      { value: 'chassisNumber', text: 'CHASSIS #' },
      { value: 'truck', text: 'TRUCK #' },
      { value: 'truckPlate', text: 'Truck Plate #' }
    ],
    autocompleteList: []
  };

  typeFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: 'type'
  });

  searchFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: 'containerNumber'
  });

  DIVFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'division'
  });

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  excelData: any;

  constructor() {
    super();
  }

  search() {
    YmsModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.searchFilter,
        this.DIVFilter,
        this.toFilter,
        this.fromFilter
      ])
    );
    YmsModule.search({
      type: this.type
    });
  }

  async excel() {
    YmsModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.searchFilter,
        this.DIVFilter,
        this.toFilter,
        this.fromFilter
      ])
    );
    await YmsModule.excel(YmsType.DAILY);
    if (YmsModule.excelData) {
      window.open(YmsModule.excelData.url);
    }
  }

  add() {
    YmsModule.setYmsType(this.type);
    YmsModule.setInventoryId(null);
    this.$bvModal.show('inventory-action-modal');
  }
}
